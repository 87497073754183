import Service from "../Service";
const resource = "TicEquipmentAssignamentNew/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(EanID, requestID) {
        return Service.post(resource + "list", {}, {
            params: { EanID: EanID, requestID: requestID },
        });
    },

    saveDevolution(obj, requestID) {
        return Service.post(resource + "saveDevolution", obj, {
            params: { requestID: requestID },
        });
    },

    listDevolution(EanID, requestID) {
        return Service.post(resource + "listDevolution", {}, {
            params: { EanID: EanID, requestID: requestID },
        });
    },
};