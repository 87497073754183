<template lang="">
	<v-card>
		<s-crud
			:config="config"
			title="Asignación de equipos"
			:filter="filter"
			search-input
			:remove="itemSelected ? itemSelected.EanStatus ? true : false : false"
			ref="scrudEquipmentAssignament"
			@rowSelected="rowSelected($event)"
			:configuration="this.$fun.isAdmin()"
			nameScrud="scrudEquipmentAssignament"
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col cols="12" md="3">
							<s-select-definition                                
							:def="1531"
							label="Tipo Asignación"
							v-model="filter.CgeTypeAssigns"
							clearable
							/>
						</v-col>
						<v-col cols="12" md="3" v-if="filter.CgeTypeAssigns == 1 || filter.CgeTypeAssigns == 2">
							<s-select-area 
								label="Area"
								v-model="filter.AreID"
							/>
						</v-col>
						<v-col cols="12" md="3" v-if="filter.CgeTypeAssigns == 3">
                            <s-select-definition                                
                            :def="1534"
                            label="Zona"
                            v-model="filter.EanZone"
                            />
                        </v-col>
						<v-col cols="12" md="3">
                            <s-date
                                v-model="filter.EanDateFrom"
                                label="Fecha desde"
                                clearable
                                />
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-date
                                v-model="filter.EanDateUntil"
                                label="Fecha hasta"
                                clearable
                                />
                        </v-col>
						<v-col cols="12" md="3">
							<s-switch label="Mostrar Inactivos" v-model="filter.EanStatus" :true-value="0" :false-value="1" />
						</v-col>
					</v-row>
				</v-container>
			</template>
			<template v-slot:AsgDevolution="{ row }">
				<v-btn v-if="row.EanStatus == 0 ? false : true" x-small color="success" @click="AsgDevolution(row)">
					<v-icon>mdi-sync</v-icon>
				</v-btn>
				<v-btn v-else x-small color="primary" @click="AsgDevolutionView(row)">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</template>
			<template v-slot:AsgAction="{ row }">
				<v-btn :disabled="row.EanStatus == 0 ? true : false" x-small color="primary" @click="AsgActionEdit(row)">
					<i class="fas fa-chalkboard-teacher"></i>
				</v-btn>
			</template>
			<template v-slot:AsgSecStatus="{ row }">
				<v-chip x-small :color="row.EanStatus == 1 ? 'success' : 'error'">
                    {{ row.EanStatus == 1 ? "Activo" : "Inactivo" }}
                </v-chip>
			</template>
			<template v-slot:options>
				<v-menu offset-y v-if="itemSelected">
					<template v-slot:activator="{ on, attrs }">
						<v-btn 
						v-bind="attrs"
						v-on="on"
						icon
						x-small
						color="white"
						>
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item 
						@click="openDialogPDF(itemSelected)"
						>
							<v-list-item-icon style="margin-right: 10px;">
								<v-icon>mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Imprimir Asignación</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item 
						@click="openDialogPDF(itemSelected, true)"
						>
							<v-list-item-icon style="margin-right: 10px;">
								<v-icon>mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Imprimir Devolución</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" small elevation="0" @click="addAssignment()">
							<i class="fas fa-chalkboard-teacher"></i>
						</v-btn>
					</template>
					<span>Agregar asignación</span>
				</v-tooltip>
			</template>
		</s-crud>

		<v-dialog
			v-model="dialogAssignment"
			v-if="dialogAssignment"
			fullscreen
			persistent
		>
			<v-card>
				<s-toolbar
					label="Asignar equipo"
					color="#8e8f91"
					dark
					:clear="worker.EanID == ''"
					@clear="clear(true)"
					close
					@close="dialogAssignment = false"
				>
				</s-toolbar>
				<v-row>
					<v-col>
						<v-card dense>
							<v-container>
								<v-row >
									<v-col cols="12" class="s-col-form" md="8" style="display: flex; align-items: center;">
										<h4>Seleccione tipo de Asignación &rArr;</h4>
									</v-col>
									<v-col cols="12" class="s-col-form" md="4">
										<s-select-definition                                
											:def="1531"
											v-model="filterEquipment.CgeTypeAssigns"
											@change="changeTypeAssign()"
											:disabled="worker.EanID == '' ? false : true"
											/>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<s-toolbar
											label="Datos del trabajador"
											color="#8e8f91"
											save
											dark
											@save="saveDialog()"
										></s-toolbar>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form" cols="12" md="12" v-if="filterEquipment.CgeTypeAssigns == 3">
										<s-select-definition                                
										:def="1534"
										label="Zona"
										v-model="worker.EanZone"
										/>
									</v-col>
									<v-col class="s-col-form" cols="12" md="6" v-if="filterEquipment.CgeTypeAssigns == 2">
										<s-toolbar-person :noEdit="!$fun.isAdmin()" label="Buscar Persona" noCamera v-model="worker.PrsDocumentNumber" @returnPerson="returnPerson($event)"/>
									</v-col>
									<v-col class="s-col-form" cols="12" :md="filterEquipment.CgeTypeAssigns == 2 ? 6 : 12" v-if="filterEquipment.CgeTypeAssigns == 1 || filterEquipment.CgeTypeAssigns == 2">
										<s-select-area 
											label="Area"
											v-model="worker.EanArea"
											:text="worker.AreName"
											v-if="searchAre"
										/>
									</v-col>
									<v-col class="s-col-form" cols="12" md="10" v-if="filterEquipment.CgeTypeAssigns == 2">
										<s-text label="Nombre" v-model="worker.NtpFullName" disabled></s-text>
									</v-col>
									<v-col class="s-col-form" cols="12" md="2" v-if="filterEquipment.CgeTypeAssigns == 2">
										<s-text label="NtpID" v-model="worker.NtpID" disabled></s-text>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form" cols="12" :md="filterEquipment.CgeTypeAssigns == 2 ? 5 : 6">
										<s-date
											label="Fecha Inicio"
											v-model="worker.EanDateFrom"
										></s-date>
									</v-col>
									<v-col class="s-col-form" cols="12" :md="filterEquipment.CgeTypeAssigns == 2 ? 5 : 6">
										<s-date
											label="Fecha Fin"
											v-model="worker.EanDateUntil"
											clearable
										></s-date>
									</v-col>
									<v-col class="s-col-form" cols="12" md="2" align-self="end" justify="center" v-if="filterEquipment.CgeTypeAssigns == 2">
										<v-btn color="primary" @click="searchDateEnd()"><v-icon>mdi-calendar-question</v-icon></v-btn>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form" cols="12" :md="filterEquipment.CgeTypeAssigns == 2 ? 4 : 12">
										<s-text
											label="Hostname"
											v-model="worker.EanHostName"
										></s-text>
									</v-col>
									<v-col class="s-col-form" cols="12" sm="4">
										<s-text
											label="N° Anydesk"
											v-model="worker.EanNumAnydesk"
											v-if="filterEquipment.CgeTypeAssigns == 2"
										></s-text>
									</v-col>
									<v-col class="s-col-form" cols="12" sm="4">
										<s-text
											label="Clave Anydesk"
											v-model="worker.EanPassword"
											v-if="filterEquipment.CgeTypeAssigns == 2"
										></s-text>
									</v-col>
								</v-row>
								<v-row>
									
								</v-row>
								<v-row>
									<v-col>
										<s-textarea
											label="Observación"
											v-model="worker.EanObservation"
										></s-textarea>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<s-toolbar
											label="Equipos asignados"
											color="#8e8f91"
											dark
										>
											<v-tooltip bottom="">
												<template v-slot:activator="{ on }">
													<v-btn text v-on="on" small @click="inizializeCamera()">
													<v-icon style="font-size:16px;">mdi-camera</v-icon></v-btn
													>
												</template>
												<span>Adjuntar foto</span>
											</v-tooltip>
										</s-toolbar>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-data-table
											:headers="headersEquipmentAssignament"
											:items="filtereditemsEquipmentAssignament"
											dense
										>
										<template v-slot:[`item.CgeDate`]="{ item }">
											{{
											item.CgeDate
												? $moment(item.CgeDate).format(
													$const.FormatDateView
												)
												: ""
											}}
										</template>
											<template v-slot:item.CptAction="{ item }">
												<v-btn
													x-small
													color="error"
													@click="deleteEquipmentWorker(item)"
												>
													<i class="fas fa-trash"></i>
												</v-btn>
											</template>
											<template v-slot:item.AsgCharacteristics="{ item }">
												<v-btn x-small style="margin-right: 10px;" color="primary" @click="AsgCharacteristics(item)">
													<v-icon>mdi-eye</v-icon>
												</v-btn>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<img v-if="!EanPhotoAssign && EanPhotoAssignDB" :src="EanPhotoAssignDB" alt="Foto desde BD" style="width: 300px; object-fit: cover; height: auto;"/>
										<img v-if="EanPhotoAssign" :src="EanPhotoAssign" alt="Foto tomada" style="width: 300px; object-fit: cover; height: auto;"/>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
					<v-col>
						<v-card dense :loading="loading">
							<v-container>
								<v-row>
									<v-col class="s-col-form">
										<s-toolbar
											label="Equipos sin asignar"
											color="#8e8f91"
											dark
										></s-toolbar>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-data-table
											:headers="headersEquipmentAssignament"
											:items="filtereditemsComponent"
											dense
										>
											<template v-slot:[`item.CgeDate`]="{ item }">
												{{
												item.CgeDate
													? $moment(item.CgeDate).format(
														$const.FormatDateView
													)
													: ""
												}}
											</template>
											<template v-slot:top>
												<v-text-field
													v-model="filterEquipment.Search"
													label="Buscar"
													class="mx-4"
													@input="searchTypeAssign()"
												></v-text-field>
											</template>

											<template v-slot:item.CptAction="{ item }">
												<v-btn
													x-small
													color="success"
													@click="addEquipmentWorker(item)"
												>
													<i class="fas fa-plus"></i>
												</v-btn>
											</template>

											<template v-slot:item.AsgCharacteristics="{ item }">
												<v-btn x-small style="margin-right: 10px;" color="primary" @click="AsgCharacteristics(item)">
													<v-icon>mdi-eye</v-icon>
												</v-btn>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogCharacteristics" v-if="dialogCharacteristics" max-width="500px">
            <v-card>
                <v-container>
                    <s-crud
                        :config="configCharacteristics"
                        title="Características"
                        :filter="filterCharacteristics"
                        close
                        :add="$fun.isAdmin()"
                        :edit="$fun.isAdmin()"
                        :remove="$fun.isAdmin()"
                        @save="saveCharacteristics($event)"
                        @close="dialogCharacteristics = false"
                        ref="scrudCharacteristics"
                    >
                        <template v-slot="props">
                            <v-container>
                                <v-row >                                        
                                    <v-col cols="12" class="s-col-form" md="6">
                                        <s-select-definition
                                            :def="1532"
                                            :dgr="CgeIDGroup"
                                            label="Nombre"
                                            v-model="props.item.EctCharacteristics"
                                            :add="$fun.isAdmin()"
                                        />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="6">
                                        <s-text                               
                                            label="Valor"
											uppercase
                                            v-model="props.item.EctValueCharacteristics"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </s-crud>
                </v-container>
            </v-card>
        </v-dialog>

		<v-dialog v-model="dialogDevolution" v-if="dialogDevolution" max-width="900px" persistent>
			<v-card>
				<s-toolbar
					label="Devolución de Equipos"
					color="#8e8f91"
					save
					dark
					:close="!totalDevolution"
					@close="dialogDevolution = false"
					@save="saveDevolution()"
				>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" small @click="inizializeCamera()">
						<v-icon style="font-size:16px;">mdi-camera</v-icon></v-btn
						>
					</template>
					<span>Adjuntar foto</span>
				</v-tooltip>
				</s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="12" md="5">
							<s-text label="Responsable" v-model="itemsDevolutionAbsolute.NtpFullName" disabled></s-text>
						</v-col>
						<v-col cols="12" md="5">
							<s-text label="Area" v-model="itemsDevolutionAbsolute.AreName" disabled></s-text>
						</v-col>
						<v-col cols="12" md="2">
							<s-text v-model="itemsDevolutionAbsolute.EanDateFromData" label="Fecha Entrega" disabled></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table
								dense
                                :items="itemsDevolution"
                                :headers="headersDevolution"
                                :items-per-page="-1"
								item-key="EadID"
                            >
								<template v-slot:[`item.TdtStatus`]="{ item }">
									<s-select-definition 
										:disabled="item.InDevolution == 1 ? false : true"                               
										:def="1539"
										v-model="item.TdtStatus"
									/>
								</template>
								<template v-slot:[`item.TdtObservation`]="{ item }">
									<s-text :disabled="item.InDevolution == 1 ? false : true" v-model="item.TdtObservation"/>
								</template>
								<template v-slot:[`item.TdtStatusDevolution`]="{ item }">
									<div style="display: flex; align-items: center; justify-content: center; height: 100%;">
										<v-checkbox style="margin: 0 !important; padding: 0 !important; height: 32px; width: 32px; top: 2px; position: relative" :disabled="item.InDevolution == 1 ? false : true" label="" dense v-model="item.TdtStatusDevolution" @change="item.TdTDateReplacement = null"></v-checkbox>
									</div>
								</template>
								<template v-slot:[`item.TdTDateReplacement`]="{ item }">
									<s-date v-if="item.TdtStatusDevolution || (item.TdtStatusDevolution == false && item.InDevolution == 0)" :disabled="item.InDevolution == 1 ? false : true" clearable v-model="item.TdTDateReplacement" label=""/>
									<s-text v-else placeholder="DD/MM/YYYY" disabled></s-text>
								</template>
								<template v-slot:[`item.AsgNewEquipment`]="{ item }">
									<v-btn :disabled="item.TdtStatusDevolution == false" x-small color="success" @click="addEvent(item)"><v-icon style="font-size:18px;">mdi-plus-circle-outline</v-icon></v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<img v-if="!EanPhotoDevolution && EanPhotoDevolutionDB" :src="EanPhotoDevolutionDB" alt="Foto desde BD" style="width: 300px; object-fit: cover; height: auto;"/>
							<img v-if="EanPhotoDevolution" :src="EanPhotoDevolution" alt="Foto tomada" style="width: 300px; object-fit: cover; height: auto;"/>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDevolutionView" v-if="dialogDevolutionView" max-width="900px" persistent>
			<v-card>
				<s-toolbar
					label="Devolución de Equipos"
					color="#8e8f91"
					dark
					close
					@close="dialogDevolutionView = false"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="12" md="5">
							<s-text label="Responsable" v-model="itemsDevolutionAbsolute.NtpFullName" disabled></s-text>
						</v-col>
						<v-col cols="12" md="5">
							<s-text label="Area" v-model="itemsDevolutionAbsolute.AreName" disabled></s-text>
						</v-col>
						<v-col cols="12" md="2">
							<s-text v-model="itemsDevolutionAbsolute.EanDateFromData" label="Fecha Entrega" disabled></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table
								dense
                                :items="itemsDevolution"
                                :headers="headersDevolution"
                                :items-per-page="-1"
								item-key="EadID"
                            >
								<template v-slot:[`item.TdtStatus`]="{ item }">
									<s-select-definition 
										:disabled="item.InDevolution == 1 ? false : true"                               
										:def="1539"
										v-model="item.TdtStatus"
									/>
								</template>
								<template v-slot:[`item.TdtObservation`]="{ item }">
									<s-text :disabled="item.InDevolution == 1 ? false : true" v-model="item.TdtObservation"/>
								</template>
								<template v-slot:[`item.TdtStatusDevolution`]="{ item }">
									<div style="display: flex; align-items: center; justify-content: center; height: 100%;">
										<v-checkbox style="margin: 0 !important; padding: 0 !important; height: 32px; width: 32px; top: 2px; position: relative" :disabled="item.InDevolution == 1 ? false : true" label="" dense v-model="item.TdtStatusDevolution" @change="item.TdTDateReplacement = null"></v-checkbox>
									</div>
								</template>
								<template v-slot:[`item.TdTDateReplacement`]="{ item }">
									<s-date v-if="item.TdtStatusDevolution || (item.TdtStatusDevolution == false && item.InDevolution == 0)" :disabled="item.InDevolution == 1 ? false : true" clearable v-model="item.TdTDateReplacement" label=""/>
									<s-text v-else placeholder="DD/MM/YYYY" disabled></s-text>
								</template>
								<template v-slot:[`item.AsgNewEquipment`]="{ item }">
									<v-btn :disabled="item.TdtStatusDevolution == false" x-small color="success" @click="addEvent(item)"><v-icon style="font-size:18px;">mdi-plus-circle-outline</v-icon></v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<img v-if="!EanPhotoDevolution && EanPhotoDevolutionDB" :src="EanPhotoDevolutionDB" alt="Foto desde BD" style="width: 300px; object-fit: cover; height: auto;"/>
							<img v-if="EanPhotoDevolution" :src="EanPhotoDevolution" alt="Foto tomada" style="width: 300px; object-fit: cover; height: auto;"/>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogReplacement"
			v-if="dialogReplacement"
			max-width="900px"
			persistent
		>
			<v-card dense>
				<s-toolbar
					label="Nuevo Equipo"
					color="#8e8f91"
					dark
					close
					@save="saveReplacement()"
					save
					@close="dialogReplacement = false"
				></s-toolbar>
				<v-container>
                    <v-row>                                        
                         <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                                :def="1530"
                                label="Equipo"
                                v-model="itemReplacement.CgeTypeEquipment"
								disabled
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                                :def="1531"
                                label="Tipo Asignación"
                                v-model="itemReplacement.CgeTypeAssigns"
								disabled
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                                :def="1533"
                                label="Marca"
                                v-model="itemReplacement.CgeBrand"
                                :add="$fun.isAdmin()"
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                            :def="1540"
                            label="Modelo"
                            v-model="itemReplacement.CgeModel"
                            :add="$fun.isAdmin()"
                            />
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: 5px !important;">
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-text                          
                                label="Serie" 
                                v-model="itemReplacement.CgeSerie"/>
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-date
                                v-model="itemReplacement.CgeDate"
                                label="Fecha Adquisición"
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="2" v-show="false">
                            <v-switch label="Asignable" v-model="itemReplacement.CgeAssigns" dense hide-details />
                        </v-col>
                    </v-row>
                </v-container>
			</v-card>
		</v-dialog>

		<v-dialog v-if="dialogPdfDevolution" v-model="dialogPdfDevolution" fullscreen persistent>
			<PdfDevolution :item="dataPdf" @close="dialogPdfDevolution = false"></PdfDevolution>
		</v-dialog>

		<v-dialog v-if="dialogPdfAsignation" v-model="dialogPdfAsignation" fullscreen persistent>
			<PdfAsignation :item="dataPdf" @close="dialogPdfAsignation = false"></PdfAsignation>
		</v-dialog>

		<v-dialog v-model="dialogCamera" v-if="dialogCamera" max-width="600px">
			<v-card>
				<s-toolbar label="Capturar Foto" dark color="#8e8f91" close @close="stopCamera()"></s-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<video style="max-width: 100%; height: auto; object-fit: cover;" ref="video" v-show="dialogCamera" autoplay></video>
							<canvas ref="canvas" style="display: none;"></canvas>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn color="success" block @click="captureImage()" v-if="dialogCamera">Tomar Foto</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import EquimentAssignamentNew from "../../../services/InformationTechnology/EquimentAssignamentNew";
	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
	import TicEquipmentService from "../../../services/InformationTechnology/TicEquipmentService";
	import TicEquipmentCharacteristic from '@/services/InformationTechnology/EquimentCharacteristicService';
	import PdfDevolution from "./PdfDevolution.vue";
	import PdfAsignation from "./PdfAsignation.vue";
	import GenUploadFiles from "../../../services/General/GenUploadFiles";

	export default {
		components: {
			sToolbarPerson,
			PdfDevolution,
			PdfAsignation
		},
		
		data() {
			return {
				isPhotoAssign: true,
				EanPhotoAssign: null,
				EanPhotoDevolution: null,
				EanPhotoAssignDB: null,
				EanPhotoDevolutionDB: null,
				dialogCamera: false,
				stream: null,
				dialogPdfDevolution: false,
				dialogPdfAsignation: false,
				dataPdf: {},
				totalDevolution: false,
				dialogReplacement: false,
				itemReplacement: {},
				itemsDevolutionAbsolute: {},
				itemsDevolution: [],
				dialogDevolution: false,
				dialogDevolutionView: false,
				headersDevolution: [
					{ text: "Equipo", value: "CgeTypeEquipmentName", width: "30%" },
					{ text: "Estado", value: "TdtStatus", width: 130 },
					{ text: "Observaciones", value: "TdtObservation" },
					{ text: "¿Reponer?", value: "TdtStatusDevolution", width: 90 },
					{ text: "Dia Reposición", value: "TdTDateReplacement", width: 140 },
					{ text: "Equipo Nuevo", value: "AsgNewEquipment", width: 120, align: "center" },
				],
				CtrEndDate: null,
				searchAre: true,
				CgeIDGroup: 0,
				dialogCharacteristics: false,
				filterCharacteristics: {
					CgeID: ""
				},
				configCharacteristics: {
					service: TicEquipmentCharacteristic,
					model: {
						EctID: "ID",
					},
					headers: [
						{ text: "Nombre", value: "EctCharacteristicsName" },
						{ text: "Valor", value: "EctValueCharacteristics" },
					]
				},
				loading : false,
				itemsEquipmentAssignament: [],
				worker : {
					EanID : "",
					NtpID : "",
					NtpFullName : "",
					PrsDocumentNumber : "",
					EanNumAnydesk : "",
					EanPassword : "",
					EanHostName : "",
					EanObservation : "",
					EanDateFrom : this.$fun.getDate(""),
					EanDateUntil : "",
					EanArea : "",
					EanZone : "",
					UsrCreateID: "",
					EanTypeAssigns : "",
					ItemsDetail : []
				},
				itemsComponent : [],
				dialogAssignment : false,
				headersEquipmentAssignament : [
					{ text: "ID", value : "CgeID"}, 
					{ text: "Equipo", value : "CgeTypeEquipmentName"},
					{ text: "Marca", value : "CgeBrandName"},
					{ text: "Modelo", value : "CgeModelName"},
					{ text: "Serie", value : "CgeSerie"},
					{ text: "Fecha de Adquisicion", value : "CgeDate"},
					{ text: "Acción", value : "CptAction", width : 50, align : "center"},
					{ text: "Visualizar", value : "AsgCharacteristics", width : 50, align : "center"},
				],
				filterEquipment : {
					CgeTypeAssigns : "",
					Search : ""
				},
				filter : {
					EanDateFrom : this.$fun.getDate(""),
					EanDateUntil : this.$fun.getDate(""),
					AreID : "",
					CgeTypeAssigns : "",
					EanZone : "",
					EanStatus : 1
				},
				config: {
					model : {
						EanID : "ID",
						AsgAction : "",
						EanDateFrom : "date",
						EanDateUntil : "date",
						AsgDevolution : "",
						AsgSecStatus : "",
					},
					service: EquimentAssignamentNew,
					headers : [
					
						{text : "ID", value : "EanID", draggable : false},
						{text : "Devolución", value : "AsgDevolution", width : 60, align : "center", draggable : false },
						{text : "Area", value : "AreName"},
						{text : "DNI", value : "PrsDocumentNumber" , width : 80},
						{text : "Apellidos y Nombres", value : "NtpFullName"},
						{text : "Zona", value : "EanZoneName"},
						{text : "Fecha Inicio", value : "EanDateFrom"},
						{text : "Fecha Fin", value : "EanDateUntil"},
						{text : "Estado", value : "AsgSecStatus", draggable : false },
						{text : "Editar", value : "AsgAction", width : 60, align : "center", draggable : false },
					]
				},
				itemSelected : {},
			}
		},

		methods: {
			rowSelected(item) {
				if(item !== null){
					this.itemSelected = item[0];
				}else{
					this.itemSelected = {};
				}
			},
			saveReplacement(){
				this.itemReplacement.UsrCreateID = this.$fun.getUserID()
				this.itemReplacement.CgeAssigns = this.itemReplacement.CgeAssigns ? 1 : 0;
				this.itemReplacement.CgeSerie = this.$fun.trimFull(this.itemReplacement.CgeSerie, true);
				this.itemReplacement.CgeStatus = 1;
				if(this.itemReplacement.CgeModel == "" || this.itemReplacement.CgeSerie.trim() == ""){
					this.$fun.alert("Debe de llenar todos los campos", "warning");
					return;
				}
				this.$fun.alertFull("¿Seguro de guardar?", "question")
				.then(async r => {
					if (r.value) {
						await TicEquipmentService.save(this.itemReplacement, this.$fun.getUserID()).then(async (r) => {
							if (r.status == 200) {
								this.$fun.alert("Se ha guardado correctamente", "success");
								this.dialogReplacement = false;
								await this.searchItemsDevolution();
							}
						})
					}
				});
			},
			addEvent(item) {
				this.itemReplacement = {};

				let data = this.itemsDevolutionAbsolute.ItemsDetail.find(i => i.EanID == item.EanID && i.CgeID == item.CgeID);

				this.itemReplacement.CgeAssigns = true;
				this.itemReplacement.CgeTypeAssigns = parseInt(data.CgeTypeAssigns);
				this.itemReplacement.CgeTypeEquipment = parseInt(data.CgeTypeEquipment);
				this.itemReplacement.CgeBrand = parseInt(data.CgeBrand);
				this.itemReplacement.CgeModel = parseInt(data.CgeModel);
				this.itemReplacement.TdtID = item.TdtID;
				this.itemReplacement.CgeIDAnt = data.CgeID;

				this.dialogReplacement = true;
			},
			async saveDevolution() {
				this.$fun.alertFull("¿Seguro de guardar?", "question")
				.then(async r => {
					if (r.value) {
						for (let i = 0; i < this.itemsDevolution.length; i++) {
							this.itemsDevolution[i].TdtStatusDevolution = this.itemsDevolution[i].TdtStatusDevolution === true || this.itemsDevolution[i].TdtStatusDevolution === false ? this.itemsDevolution[i].TdtStatusDevolution ? 1 : 0 : 0;
							if(!this.itemsDevolution[i].TdtID && !this.totalDevolution){
								this.totalDevolution = false;
								let total = this.itemsDevolution.length;
								let countDevolution = 0;
								for (let e = 0; e < this.itemsDevolution.length; e++) {
									if (!this.itemsDevolution[e].TdtStatusDevolution) countDevolution++;
								}
								if(countDevolution == total)this.totalDevolution = true;
							}
							if(this.totalDevolution){
								this.itemsDevolution[i].TdtDevolutionComplete = 1;
							}
							await EquimentAssignamentNew.saveDevolution(this.itemsDevolution[i], this.$fun.getUserID()).then(resp => {
								if (resp.status == 200) {
								}
							})
						}

						if (this.EanPhotoDevolution) {
							try {
								// console.log("this.EanPhotoDevolution", this.EanPhotoDevolution);
								const respImg = await GenUploadFiles.upload(this.EanPhotoDevolution, `EanPhotoDevolution_${this.itemsDevolutionAbsolute.EanID}`, 'routePrueba', true);
								
								this.itemsDevolutionAbsolute.EanPhotoDevolution = respImg.data.fileName;
								await EquimentAssignamentNew.save(this.itemsDevolutionAbsolute, this.$fun.getUserID());
							} catch (error) {
								this.$fun.alert("Error al subir la foto", "warning");
								console.error("Error al subir la foto", error);
							}
						}

						// if(this.totalDevolution){
							this.openDialogPDF(this.itemsDevolutionAbsolute, true);
						// }

						this.$fun.alert("Se ha guardado la devolución", "success");
						this.$refs.scrudEquipmentAssignament.refresh();
						this.dialogDevolution = false;
					}
				})
			},
			async AsgDevolution(row) {
				await this.clear();
				await EquimentAssignamentNew.list(row.EanID, this.$fun.getUserID()).then(resp => {
					if (resp.status == 200) {
						this.itemsDevolutionAbsolute = resp.data;
					}
				});
				await this.searchItemsDevolution();
				this.itemsDevolutionAbsolute.EanDateFromData = this.$fun.formatDateView(this.itemsDevolutionAbsolute.EanDateFrom);
				this.dialogDevolution = true;
				this.isPhotoAssign = false;
				this.getphotoBD();
			},
			async AsgDevolutionView(row) {
				await EquimentAssignamentNew.list(row.EanID, this.$fun.getUserID()).then(resp => {
					if (resp.status == 200) {
						this.itemsDevolutionAbsolute = resp.data;
					}
				});
				await this.searchItemsDevolution();
				this.itemsDevolutionAbsolute.EanDateFromData = this.$fun.formatDateView(this.itemsDevolutionAbsolute.EanDateFrom);
				this.dialogDevolutionView = true;
				this.getphotoBD();
			},
			async searchItemsDevolution(){
				this.totalDevolution = false;
				let total = 0;
				let countDevolution = 0;

				await EquimentAssignamentNew.listDevolution(this.itemsDevolutionAbsolute.EanID, this.$fun.getUserID()).then(resp => {
					if (resp.status == 200) {
						total = resp.data.length;
						for (let i = 0; i < resp.data.length; i++) {
							resp.data[i].TdtStatusDevolution = resp.data[i].TdtStatusDevolution ? true : false;
							if (!resp.data[i].InDevolution) countDevolution++;
						}
						this.itemsDevolution = resp.data;
					}
				})
				if(countDevolution == total) this.totalDevolution = true;
			},
			searchDateEnd() {
				const invalidDate = new Date(1900, 0, 1);

				if (!this.CtrEndDate || new Date(this.CtrEndDate).getTime() === invalidDate.getTime()) {
					this.worker.EanDateUntil = null;
					this.$fun.alert("Esta persona tiene fecha indefinida", "warning");
				} else {
					this.worker.EanDateUntil = this.CtrEndDate;
				}
			},
			async saveDialog(){
				if(this.filterEquipment.CgeTypeAssigns == 1 || this.filterEquipment.CgeTypeAssigns == 2){
					if(this.worker.EanArea == "" || this.worker.EanArea == null){
						this.$fun.alert("Seleccione una area", "warning");
						return;
					}
				}
				if(this.worker.EanHostName == "" || this.worker.EanHostName == null){
					this.$fun.alert("Ingrese HostName", "warning");
					return;
				}
				if(this.itemsEquipmentAssignament.length == 0){
					this.$fun.alert("Seleccione al menos un equipo", "warning");
					return;
				}

				for (let i = 0; i < this.itemsEquipmentAssignament.length; i++) {
					this.itemsEquipmentAssignament[i].UsrCreateID = this.$fun.getUserID();
				}

				this.worker.EanTypeAssigns = this.filterEquipment.CgeTypeAssigns;
				this.worker.UsrCreateID = this.$fun.getUserID();
				this.worker.ItemsDetail = this.itemsEquipmentAssignament;
				this.$fun.alert("¿Seguro de guardar?", "question")
					.then(async r => {
						if(r.value){
							// console.log("this.worker", this.worker);
							const resp = await EquimentAssignamentNew.save(this.worker, this.$fun.getUserID());
							this.dialogAssignment = false;
							if (this.EanPhotoAssign) {
								try {
									// console.log("this.EanPhotoAssign", this.EanPhotoAssign);
									const respImg = await GenUploadFiles.upload(this.EanPhotoAssign, `EanPhotoAssign_${resp.data.EanID}`, 'routePrueba', true);
									
									this.worker.EanPhotoAssign = respImg.data.fileName;
									this.worker.EanID = resp.data.EanID;
									await EquimentAssignamentNew.save(this.worker, this.$fun.getUserID());
								} catch (error) {
									// this.$fun.alert("Error al subir la foto", "warning");
                                    // console.error("Error al subir la foto", error);
								}
							}
							this.openDialogPDF(resp.data);
							this.$fun.alert("Se ha guardado correctamente", "success");
							this.$refs.scrudEquipmentAssignament.refresh();
						}
					});
			},
			async openDialogPDF(item, devolution = false){
				await EquimentAssignamentNew.list(item.EanID, this.$fun.getUserID()).then(resp => {
					if (resp.status == 200) {
						this.dataPdf = resp.data;
					}
				});
				if(devolution){
					this.dialogPdfDevolution = true;
				}else{
					this.dialogPdfAsignation = true;
				}
			},
			async addAssignment(){
				await this.changeTypeAssign();
				this.clear();
				// this.filterEquipment.CgeTypeAssigns = 2;
				this.dialogAssignment = true;
			},
			clear(question = false){
				if(question){
					this.$fun.alert("¿Seguro de limpiar?", "question")
					.then(r => {
						if(r.value){
							this.worker = {
								EanID : "",
								NtpID : "",
								NtpFullName : "",
								PrsDocumentNumber : "",
								EanNumAnydesk : "",
								EanPassword : "",
								EanHostName : "",
								EanObservation : "",
								EanDateFrom : this.$fun.getDate(""),
								EanDateUntil : "",
								EanArea : "",
								EanZone : "",
								EanTypeAssigns : "",
								ItemsDetail : []
							};
							this.itemsEquipmentAssignament = [];
							this.EanPhotoAssign = null;
							this.EanPhotoAssignDB = null;
							this.isPhotoAssign = true;
							this.EanPhotoDevolution = null;
							this.EanPhotoDevolutionDB = null;
						}
					});
				}else{
					this.worker = {
						EanID : "",
						NtpID : "",
						NtpFullName : "",
						PrsDocumentNumber : "",
						EanNumAnydesk : "",
						EanPassword : "",
						EanHostName : "",
						EanObservation : "",
						EanDateFrom : this.$fun.getDate(""),
						EanDateUntil : "",
						EanArea : "",
						EanZone : "",
						EanTypeAssigns : "",
						ItemsDetail : []
					};
					this.itemsEquipmentAssignament = [];
					this.EanPhotoAssign = null;
					this.EanPhotoAssignDB = null;
					this.isPhotoAssign = true;
					this.EanPhotoDevolution = null;
					this.EanPhotoDevolutionDB = null;
				}
			},
			returnPerson(data){
				if(!data){
					this.worker.NtpFullName = "";
					this.worker.NtpID = "";
					this.worker.EanArea = "";
					this.worker.AreName = "";
					this.CtrEndDate = "";
					this.worker.EanDateUntil = "";
				}else{
					this.worker.NtpFullName = data.NtpFullName;
					this.worker.NtpID = data.NtpID;
					this.worker.EanArea = data.AreID;
					this.worker.AreName = data.AreName;
					this.CtrEndDate = data.CtrEndDate;
					this.searchAre = false;
					setTimeout(() => {
						this.searchAre = true;
					}, 0);
				}
			},
			async AsgActionEdit(item){
				await this.clear();
				await EquimentAssignamentNew.list(item.EanID, this.$fun.getUserID()).then(resp => {
					if (resp.status == 200) {
						// console.log("resp.data", resp.data);
						this.worker = resp.data;
						this.itemsEquipmentAssignament = resp.data.ItemsDetail;
					}
				});
				this.filterEquipment.CgeTypeAssigns = item.EanTypeAssigns;
				this.getphotoBD();
				this.dialogAssignment = true;
			},
			addEquipmentWorker(item){
				this.itemsComponent.splice(this.itemsComponent.indexOf(item), 1);
            	this.itemsEquipmentAssignament.push(item);
			},
			deleteEquipmentWorker(item){
				if(item.EadID == null){
					this.itemsEquipmentAssignament.splice(this.itemsEquipmentAssignament.indexOf(item), 1);
					this.changeTypeAssign();
				}else{
					const index = this.itemsEquipmentAssignament.indexOf(item);
					if (index !== -1) {
						this.itemsEquipmentAssignament[index].SecStatus = 0;
						this.itemsEquipmentAssignament[index].EadStatus = 0;
					}
					this.changeTypeAssign();
				}
			},
			async changeTypeAssign(){
				if(this.worker.EanID == "" || this.worker.EanID == null){
					this.clear();
				}
				this.loading = true;
				await TicEquipmentService.list(this.filterEquipment, this.$fun.getUserID())
				.then((r) => {               
					if (r.status == 200) { 
						this.itemsComponent = r.data;
						this.loading = false;      
					}
				});
			},
			async searchTypeAssign(){
				this.loading = true;
				await TicEquipmentService.list(this.filterEquipment, this.$fun.getUserID())
				.then((r) => {               
					if (r.status == 200) { 
						this.itemsComponent = r.data;
						// console.log("r.data", r.data);
						this.loading = false;      
					}
				});
			},
			clearHeaders(){
				const headersToRemove = ["PrsDocumentNumber", "NtpFullName", "EanZoneName", "AreName"];

				headersToRemove.forEach(headerValue => {
					const indexToRemove = this.config.headers.findIndex(header => header.value === headerValue);
					if (indexToRemove !== -1) {
						this.config.headers.splice(indexToRemove, 1);
					}
				});
			},
			AsgCharacteristics(row){
				this.filterCharacteristics.CgeID = row.CgeID;
				this.dialogCharacteristics = true;
				this.CgeIDGroup = row.DedHelper2 ? parseInt(row.DedHelper2) : 0;
			},
			saveCharacteristics(item){
				item.UsrCreateID = this.$fun.getUserID()
				item.CgeID = this.filterCharacteristics.CgeID
				item.save();
			},
			async inizializeCamera() {
				try {
					this.dialogCamera = true;
					this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
					this.$refs.video.srcObject = this.stream;
				} catch (error) {
					console.error("Error al acceder a la cámara:", error);
				}
			},
			captureImage() {
				const video = this.$refs.video;
				const canvas = this.$refs.canvas;
				const contexto = canvas.getContext("2d");

				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				contexto.drawImage(video, 0, 0, canvas.width, canvas.height);

				if(this.isPhotoAssign){
					this.EanPhotoAssign = canvas.toDataURL("image/png");
				}else{
					this.EanPhotoDevolution = canvas.toDataURL("image/png");
				}

				this.stopCamera();
			},
			stopCamera() {
				this.dialogCamera = false;
				if (this.stream) {
					this.stream.getTracks().forEach(track => track.stop());
					this.stream = null;
				}
			},
			getphotoBD() {
				GenUploadFiles.getFile("routePrueba", this.worker.EanPhotoAssign, true)
				.then(base64 => {
					this.EanPhotoAssignDB = base64;
				})
				.catch(error => console.error("Error al obtener imagen:", error));

				GenUploadFiles.getFile("routePrueba", this.itemsDevolutionAbsolute.EanPhotoDevolution, true)
				.then(base64 => {
					this.EanPhotoDevolutionDB = base64;
				})
				.catch(error => console.error("Error al obtener imagen:", error));

			},
		},
		computed: {
			filtereditemsEquipmentAssignament() {
				return this.itemsEquipmentAssignament.filter(item => item.SecStatus !== 0);
			},
			filtereditemsComponent(){
				return this.itemsComponent.filter(item => !this.itemsEquipmentAssignament.some(equipment => equipment.CgeID === item.CgeID));
			}
		},
		watch: {
			"filter.CgeTypeAssigns"() {
				if (this.filter.CgeTypeAssigns == 1) {
					this.clearHeaders();

					const headersToAdd = [
						{ text: "Area", value: "AreName" }
					];

					let contador_initial = 2;
					headersToAdd.forEach(header => {
						const headerExists = this.config.headers.some(h => h.value === header.value);
						if (!headerExists) {
							this.config.headers.splice(contador_initial, 0, header);
						}
					});
				}
				else if (this.filter.CgeTypeAssigns == 2) {
					this.clearHeaders();

					const headersToAdd = [
						{ text: "Area", value: "AreName" },
						{text : "DNI", value : "PrsDocumentNumber" , width : 80},
						{text : "Apellidos y Nombres", value : "NtpFullName"},
					];

					let contador_initial = 2;
					headersToAdd.forEach(header => {
						
						const headerExists = this.config.headers.some(h => h.value === header.value);
						if (!headerExists) {
							this.config.headers.splice(contador_initial, 0, header);
							contador_initial++;
						}
					});
				}
				else if (this.filter.CgeTypeAssigns == 3) {
					this.clearHeaders();

					const headersToAdd = [
						{text : "Zona", value : "EanZoneName"}
					];

					let contador_initial = 2;
					headersToAdd.forEach(header => {
						
						const headerExists = this.config.headers.some(h => h.value === header.value);
						if (!headerExists) {
							this.config.headers.splice(contador_initial, 0, header);
							contador_initial++;
						}
					});
				}
				else {
					this.clearHeaders();

					const headersToAdd = [
						{ text: "Area", value: "AreName" },
						{text : "DNI", value : "PrsDocumentNumber" , width : 80},
						{text : "Apellidos y Nombres", value : "NtpFullName"},
						{text : "Zona", value : "EanZoneName"}
					];

					let contador_initial = 2;
					headersToAdd.forEach(header => {
						
						const headerExists = this.config.headers.some(h => h.value === header.value);
						if (!headerExists) {
							this.config.headers.splice(contador_initial, 0, header);
							contador_initial++;
						}
					});
				}
				this.filter.AreID = null;
				this.filter.EanZone = null;
			},
		},
	}
</script>

<style>
.v-dialog--fullscreen{
    backdrop-filter: blur(2px);
	background-color: rgba(0, 0, 0, 0.6);
}
</style>