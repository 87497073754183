<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Asignación de Equipos"
                dark
                :color="'#8e8f91'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<v-col align="right">
						<v-btn 
							fab
                            style="margin-right: 40px;"
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
                        <v-btn 
							fab 
                            x-small
							color="success"
							@click="saveSignaturesAndFingerPrints()"
						>	<v-icon medium>mdi-content-save</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
                <hr>
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="`Asignación.pdf`"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                :htmlToPdfOptions = "htmlToPdfOptions"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
                <section slot="pdf-content">
                    <div style="font-family: Arial, Helvetica, sans-serif; width: 710px; margin: 30px auto; padding: 0px; box-sizing: border-box; font-size: 11px;">
                        <!-- Encabezado con 3 secciones -->
                        <div style="display: flex; width: 100%;">
                            <!-- Sección Izquierda - Logo -->
                            <div style="flex: 0.7; display: flex; justify-content: center; align-items: center;  padding: 10px;">
                                <img src="/static/logo_sp.png" alt="Logo" style="width: 140px; height: auto;">
                            </div>

                            <!-- Sección Central - Título -->
                            <div style="flex: 1.5; display: flex; justify-content: center; align-items: center;  padding: 10px; text-align: center;">
                                <h2 style="margin: 0; font-size: 13px; font-weight: bold; line-height: 14px;">ACTA DE ASIGNACIÓN DE EQUIPO INFORMÁTICO Y POLÍTICA DE USO</h2>
                            </div>

                            <!-- Sección Derecha - Información -->
                            <div style="flex: 1; font-size: 9.5px; text-align: center; border-bottom: 2px solid #000; font-weight: bold; line-height: 12px;">
                                <div style="display: flex; gap: 2px;">
                                    <div style="flex: 1.5; text-align: end; white-space: nowrap;">CÓDIGO&nbsp;&nbsp;&nbsp;:</div>
                                    <div style="flex: 1; text-align: start;">AS-FR-002</div>
                                </div>
                                <div style="display: flex; gap: 2px;">
                                    <div style="flex: 1.5; text-align: end; white-space: nowrap;">VERSIÓN&nbsp;&nbsp;&nbsp;:</div>
                                    <div style="flex: 1; text-align: start;">01</div>
                                </div>
                                <div style="display: flex; gap: 2px;">
                                    <div style="flex: 1.5; text-align: end; white-space: nowrap;">Fecha de Creación&nbsp;&nbsp;&nbsp;:</div>
                                    <div style="flex: 1; text-align: start;">23/02/2024</div>
                                </div>
                                <div style="display: flex; gap: 2px;">
                                    <div style="flex: 1.5; text-align: end; white-space: nowrap;">Última Revisión&nbsp;&nbsp;&nbsp;:</div>
                                    <div style="flex: 1; text-align: start;">26/02/2024</div>
                                </div>
                                <div style="display: flex; gap: 2px;">
                                    <div style="flex: 1.5; text-align: end; white-space: nowrap;">Páginas&nbsp;&nbsp;&nbsp;:</div>
                                    <div style="flex: 1; text-align: start;"> de </div>
                                </div>
                                <div style="display: flex; gap: 2px;">
                                    <div style="flex: 1.5; text-align: end; white-space: nowrap;">Anexo&nbsp;&nbsp;&nbsp;:</div>
                                    <div style="flex: 1; text-align: start;">0</div>
                                </div>
                            </div>
                        </div>

                        <!-- Contenido principal -->
                        <p style="margin-top: 20px; margin-bottom: 16px; line-height: 12px;">
                            En conformidad con las políticas establecidas por la empresa y en cumplimiento de las normativas de seguridad de la información, se procede a la entrega del siguiente equipo informático al usuario identificado a continuación:
                        </p>
                        <div style="display: flex; width: 100%; background-color: #BFBFBF; font-weight: bold; border-radius: 2px; line-height: 14px;">
                            <div style="flex: 1.5; padding: 2px 10px;">Datos de Usuario</div>
                            <div style="flex: 1; display: flex;">
                                <div style="width: 90px; background-color: #D9D9D9; padding: 2px 10px;">Usuario de Dominio</div>
                                <div style="width: 186px; background-color: #FFF; padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{item.EanHostName}}</div>
                            </div>
                        </div>
                        <div style="display: flex; width: 100%;">
                            <div style="display: flex; flex: 1;">
                                <div style="background-color: #F2F2F2; font-weight: bold; width: 80px; padding: 2px 10px;">Nombre</div>
                                <div style="width: 100%; padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{item.NtpFullName}}</div>
                            </div>
                            <div style="display: flex; flex: 1;">
                                <div style="background-color: #F2F2F2; font-weight: bold; width: 80px; padding: 2px 10px;">DNI</div>
                                <div style="width: 100%; padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{item.PrsDocumentNumber}}</div>
                            </div>
                        </div>
                        <div style="display: flex; width: 100%;">
                            <div style="display: flex; flex: 1;">
                                <div style="background-color: #F2F2F2; font-weight: bold; width: 80px; padding: 2px 10px;">Cargo</div>
                                <div style="width: 100%; padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{item.PstName}}</div>
                            </div>
                            <div style="display: flex; flex: 1;">
                                <div style="background-color: #F2F2F2; font-weight: bold; width: 80px; padding: 2px 10px;">Área</div>
                                <div style="width: 100%; padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{item.AreName}}</div>
                            </div>
                        </div>
                        <template v-for="(itemDetail) in item.ItemsDetail">
                            <div style="display: flex; width: 100%; background-color: #BFBFBF; font-weight: bold; padding: 2px 10px; border-radius: 2px;">
                                Datos del Equipo
                            </div>
                            <div style="display: grid; grid-template-columns: 120px 143.3px 80px 143.3px 80px 143.3px; width: 100%; line-height: 14px;">
                                <div style="padding: 2px 10px; font-weight: bold; background-color: #F2F2F2; grid-row: span 2 / span 2;">Tipo de Equipo</div>
                                <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word; grid-row: span 2 / span 2;">{{ itemDetail.CgeTypeEquipmentName }}</div>
                                <div style="padding: 2px 10px; font-weight: bold; background-color: #F2F2F2;">Marca</div>
                                <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{ itemDetail.CgeBrandName}}</div>
                                <div style="padding: 2px 10px; font-weight: bold; background-color: #F2F2F2; grid-column-start: 3; grid-row-start: 2;">Número de Serie</div>
                                <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word; grid-column-start: 4; grid-row-start: 2;">{{itemDetail.CgeSerie}}</div>
                                <div style="padding: 2px 10px; font-weight: bold; background-color: #F2F2F2; grid-row: span 2 / span 2; grid-column-start: 5; grid-row-start: 1;">Modelo</div>
                                <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word; grid-row: span 2 / span 2; grid-column-start: 6; grid-row-start: 1;">{{ itemDetail.CgeModelName }}</div>
                            </div>
                            <div style="display: flex; width: 100%; background-color: #BFBFBF; font-weight: bold; padding: 2px 10px; border-radius: 2px;">
                                Detalles del Equipo
                            </div>
                            <div style="display: grid; grid-template-columns: repeat(4, 100px 77.5px); width: 100%; line-height: 14px;">
                                <template v-for="(itemCharacteristics) in itemDetail.ItemsCharacteristics">
                                    <div style="padding: 2px 10px; word-break: break-word; overflow-wrap: break-word; background-color: #F2F2F2; font-weight: bold;">{{ itemCharacteristics.EctCharacteristicsName }}</div>
                                    <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{ itemCharacteristics.EctValueCharacteristics }}</div>
                                </template>
                            </div>
                            <div style="display: flex; width: 100%; background-color: #BFBFBF; font-weight: bold; padding: 2px 10px; border-radius: 2px;">
                                Accesorios
                            </div>
                            <template v-for="(itemAccessory) in itemDetail.ItemsAccessory">
                                <div v-if="validatedItems(itemAccessory)" style="display: grid; grid-template-columns: repeat(7, 101.43px); width: 100%; line-height: 14px;">
                                    <div style="background-color: #D9D9D9; font-weight: bold; padding: 2px 10px;">{{ itemAccessory.EayAccessoryName }}</div>
                                    <div style="padding: 2px 10px; word-break: break-word; overflow-wrap: break-word; background-color: #F2F2F2; font-weight: bold;">Marca</div>
                                    <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{ itemAccessory.EayBrandName }}</div>
                                    <div style="padding: 2px 10px; word-break: break-word; overflow-wrap: break-word; background-color: #F2F2F2; font-weight: bold;">Modelo</div>
                                    <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{ itemAccessory.EayModelName }}</div>
                                    <div style="padding: 2px 10px; word-break: break-word; overflow-wrap: break-word; background-color: #F2F2F2; font-weight: bold;">Serie</div>
                                    <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{ itemAccessory.EaySerie }}</div>
                                </div>
                                <div v-else style="display: grid; grid-template-columns: 101.43px 1fr; width: 100%; line-height: 14px;">
                                    <div style="background-color: #D9D9D9; font-weight: bold; padding: 2px 10px;">{{ itemAccessory.EayAccessoryName }}</div>
                                    <div style="padding: 2px 5px; word-break: break-word; overflow-wrap: break-word;">{{ itemAccessory.EayObservation }}</div>
                                </div>
                            </template>
                        </template>

                        <div style="display: flex; width: 100%; background-color: #BFBFBF; font-weight: bold; padding: 2px 10px; border-radius: 2px;">
                            Políticas de Uso y Responsabilidades del Usuario
                        </div>
                        <div style="width: 100%; text-align: justify; padding: 10px 10px 10px 30px; line-height: 12px;">
                            <p style="margin-bottom: 4px;">
                                El usuario se compromete a solo utilizar el equipo informático que le ha sido asignado por la empresa exclusivamente para fines laborales relacionados con su cargo.
                            </p>
                            <p style="margin-bottom: 4px;">
                                El usuario es responsable de mantener el equipo informático y sus accesorios en condiciones óptimas de funcionamiento y conservación, evitando cualquier acción que pueda causar daños, pérdidas o deterioro al equipo y tomar las medidas necesarias para proteger la confidencialidad e integridad de los datos e información almacenados en este.
                            </p>
                            <p style="margin-bottom: 4px;">
                                El usuario se compromete a respetar las normativas de seguridad de la información establecidas por la empresa, incluyendo la política AS-PO-008 Política de Seguridad de la Información, que rige el buen uso y protección de los activos de información.
                            </p>
                            <p style="margin-bottom: 4px;">
                                El usuario deberá permitir la instalación de actualizaciones de software y realizar el mantenimiento básico del equipo, como la limpieza física y la organización de archivos.
                            </p>
                            <p style="margin-bottom: 4px;">
                                El usuario autorizado debe emplear VPN para acceder de forma segura a los sistemas informáticos de la empresa durante el teletrabajo o trabajo remoto, cuando sea pertinente.
                            </p>
                            <p style="margin-bottom: 4px;">
                                Se prohíbe terminantemente al usuario realizar las siguientes acciones:
                            </p>
                            <ul style="margin-left: 20px;">
                                <li>
                                    Iniciar sesión en equipos que no le hayan sido asignados por la empresa.
                                </li>
                                <li>
                                    Desactivar, eludir o vulnerar los controles de seguridad configurados en el equipo.
                                </li>
                                <li>
                                    Acceder a información no autorizada.
                                </li>
                                <li>
                                    Instalar software no autorizado por la empresa.
                                </li>
                                <li>
                                    Compartir cuentas de usuario o contraseñas con terceros.
                                </li>
                            </ul>
                            <p style="margin-bottom: 4px; margin-top: 4px;">
                                En caso de pérdida, robo o daño del equipo informático, el usuario deberá notificar de inmediato al área de TI y colaborar en cualquier investigación relacionada
                            </p>
                            <p style="margin-bottom: 4px;">
                                El incumplimiento de las políticas de uso y seguridad de la información puede conllevar a medidas disciplinarias, sanciones económicas o acciones legales, de acuerdo con lo establecido en el Reglamento Interno de Trabajo y la normativa vigente.
                            </p>
                            <p style="margin-bottom: 4px;">
                                Al finalizar su relación laboral con la empresa, el usuario deberá devolver el equipo asignado en las mismas condiciones en que fue recibido, con excepción del desgaste normal por su uso adecuado.
                            </p>
                            <p style="margin-bottom: 4px;">
                                La aceptación de estas políticas por parte del usuario se realiza mediante la firma de este documento, lo cual indica su compromiso de cumplir con las disposiciones aquí establecidas
                            </p>
                        </div>
                        <div style="display: flex; width: 100%; background-color: #BFBFBF; font-weight: bold; padding: 2px 10px; border-radius: 2px;">
                            Observaciones o comentarios
                        </div>
                        <p style="text-align: justify; padding: 2px 10px;">
                            El usuario ha recibido el equipo informático mencionado en condiciones óptimas de funcionamiento y sin defectos aparentes y ha sido notificado que sólo se realizarán copias de respaldo de los datos de su cuenta de usuario.
                        </p>
                        <p style="margin-top: 40px;">
                            Por la presente, ambas partes acuerdan y firman esta acta en señal de conformidad:
                        </p>
                        
                        <div style="display: flex; margin-top: 10px;">
                            <div style="flex: 1; margin-top: 80px; display: flex; justify-content: center;">
                                <p style="font-size: 12px; display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative; margin: 0;">
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative; max-width: max-content;">
                                        <div style="width: 100%; height: 80px; display: flex; justify-content: center; align-items: center; position: absolute; top: -80px;">
                                            <div style="width: 100%; display: flex;">
                                                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; cursor: pointer;" @click="funDialogSignatureUser()">
                                                    <span v-if="!EanSignatureUserAssign && !EanSignatureUserAssignDB" style="height:80px; width: 100%;"></span>
                                                    <img :src="EanSignatureUserAssign" v-if="EanSignatureUserAssign" 
                                                    alt="Firma Usuario" 
                                                    style="max-width: 100%; height: 80px; object-fit: contain; padding: 5px;"/>
                                                    <img :src="EanSignatureUserAssignDB" v-if="!EanSignatureUserAssign && EanSignatureUserAssignDB" 
                                                    alt="Firma Usuario" 
                                                    style="max-width: 100%; height: 80px; object-fit: contain; padding: 5px;"/>
                                                </div>
                                                <div style="width: 60px; height: 80px; border: 1px solid #000; text-align: center; display: flex; justify-content: center; align-items: center;">
                                                    <s-finger-print v-if="EanFingerPrintUserAssignDB && !EanFingerPrintUserAssign" contentIMG :srcImg="EanFingerPrintUserAssignDB" notBtn :stylesDiv="{ width: '60px', height: '80px', 'cursor': 'pointer' }" @fingerprintSaved="handleFingerprintUser"></s-finger-print>
                                                    <s-finger-print v-if="!EanFingerPrintUserAssignDB && !EanFingerPrintUserAssign" contentIMG notBtn :stylesDiv="{ width: '60px', height: '80px', 'cursor': 'pointer' }" @fingerprintSaved="handleFingerprintUser"></s-finger-print>
                                                    <s-finger-print v-if="EanFingerPrintUserAssign" contentIMG :srcImg="EanFingerPrintUserAssign" notBtn :stylesDiv="{ width: '60px', height: '80px', 'cursor': 'pointer' }" @fingerprintSaved="handleFingerprintUser"></s-finger-print>
                                                </div>
                                            </div>
                                        </div>
                                        <span style="border-top: 1px solid #000;">&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-weight: bold;">Nombre : </span>{{ item.NtpFullName }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span><span style="font-weight: bold;">DNI : </span>{{ item.PrsDocumentNumber }}</span>
                                        <span><span style="font-weight: bold;">Fecha : </span>{{ this.$fun.formatDateView(item.SecCreate) }}</span>
                                    </div>
                                </p>
                            </div>
                            <div style="flex: 1; margin-top: 80px; display: flex; justify-content: center;">
                                <p style="font-size: 12px; display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative; margin: 0;">
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative; max-width: max-content;">
                                        <div style="width: 100%; height: 80px; display: flex; justify-content: center; align-items: center; position: absolute; top: -80px;">
                                            <div style="width: 100%; display: flex;">
                                                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; cursor: pointer;" @click="funDialogSignatureResponsible">
                                                    <span style="height:80px; width: 100%;" v-if="!EanSignatureResponsibleAssign && !EanSignatureResponsibleAssignDB"></span>
                                                    <img :src="EanSignatureResponsibleAssign" v-if="EanSignatureResponsibleAssign" 
                                                    alt="Firma Responsable" 
                                                    style="max-width: 80%; height: 80px; object-fit: contain; padding: 5px;" />
                                                    <img :src="EanSignatureResponsibleAssignDB" v-if="EanSignatureResponsibleAssignDB && !EanSignatureResponsibleAssign" 
                                                    alt="Firma Responsable" 
                                                    style="max-width: 80%; height: 80px; object-fit: contain; padding: 5px;" />
                                                </div>
                                                <div style="width: 60px; height: 80px; border: 1px solid #000; text-align: center; display: flex; justify-content: center; align-items: center;">
                                                    <s-finger-print v-if="EanFingerPrintResponsibleAssignDB && !EanFingerPrintResponsibleAssign" contentIMG :srcImg="EanFingerPrintResponsibleAssignDB" notBtn :stylesDiv="{ width: '60px', height: '80px', 'cursor': 'pointer' }" @fingerprintSaved="handleFingerprintResponsible"></s-finger-print>
                                                    <s-finger-print v-if="!EanFingerPrintResponsibleAssignDB && !EanFingerPrintResponsibleAssign" contentIMG notBtn :stylesDiv="{ width: '60px', height: '80px', 'cursor': 'pointer' }" @fingerprintSaved="handleFingerprintResponsible"></s-finger-print>
                                                    <s-finger-print v-if="EanFingerPrintResponsibleAssign" contentIMG :srcImg="EanFingerPrintResponsibleAssign" notBtn :stylesDiv="{ width: '60px', height: '80px', 'cursor': 'pointer' }" @fingerprintSaved="handleFingerprintResponsible"></s-finger-print>
                                                </div>
                                            </div>
                                        </div>
                                        <span style="border-top: 1px solid #000; min-width: 240px;">&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;">Nombre : </span>{{ item.UsrCreateName? item.UsrCreateName : this.$fun.getUserInfo().NtpFullName }} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span><span style="font-weight: bold;">DNI : </span>{{ item.UsrCreatePrsDocumentNumber ? item.UsrCreatePrsDocumentNumber : this.$fun.getUserInfo().PrsDocumentNumber }}</span>
                                        <span><span style="font-weight: bold;">Fecha : </span>{{ this.$fun.formatDateView(item.SecCreate) }}</span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                </vue-html2pdf>
        </v-card>
        <v-dialog v-model="dialogSignatureUser" v-if="dialogSignatureUser" max-width="800px" persistent>
            <v-card>
                <s-toolbar label="Firma del Usuario" dark color="#8e8f91" close @close="dialogSignatureUser = false"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-signature-pad ref="signaturePadUser" style="height: 400px;" @save-signature="handleSignatureUser" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSignatureResponsible" v-if="dialogSignatureResponsible" max-width="800px" persistent>
            <v-card>
                <s-toolbar label="Firma del Responsable" dark color="#8e8f91" close @close="dialogSignatureResponsible = false"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-signature-pad ref="signaturePadResponsible" style="height: 400px;" @save-signature="handleSignatureResponsible" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sHelper from "@/services/HelperService";
    import EquimentAssignamentNew from "../../../services/InformationTechnology/EquimentAssignamentNew";
    import GenUploadFiles from "../../../services/General/GenUploadFiles";
    import sFingerPrint from "@/components/Utils/RecursosHm/sFingerPrint";

    export default{ 
       
        props:{
            item: {
				type: Object,
				default: null,
			},
        },
        components:{
            VueHtml2pdf,
            sFingerPrint
        },
        data(){
            return{
                EanSignatureUserAssign: null,
                EanFingerPrintUserAssign: null,
                EanSignatureResponsibleAssign: null,
                EanFingerPrintResponsibleAssign: null,

                EanSignatureUserAssignDB: null,
                EanFingerPrintUserAssignDB: null,
                EanSignatureResponsibleAssignDB: null,
                EanFingerPrintResponsibleAssignDB: null,

                dialogSignatureUser: false,
                dialogSignatureResponsible: false,
                htmlToPdfOptions: {
                    filename: `Asignación_a_${this.item.NtpFullName}_${this.item.PrsDocumentNumber}.pdf`,
                    jsPDF: {
                        unit: 'in',
                        format: 'a4',
                        orientation: 'portrait'
                    },
                    html2canvas: {
                    useCORS: true,
                    scale: 7,
                    },
                },
            }
        },
        methods:{
            funDialogSignatureUser(){
                this.dialogSignatureUser = true;
            },
            funDialogSignatureResponsible(){
                this.dialogSignatureResponsible = true;
            },
            handleSignatureUser(image) {
                this.EanSignatureUserAssign = image;
                this.dialogSignatureUser = false;
            },
            handleSignatureResponsible(image) {
                this.EanSignatureResponsibleAssign = image;
                this.dialogSignatureResponsible = false;
            },
            handleFingerprintUser(image) {
                this.EanFingerPrintUserAssign = image;
            },
            handleFingerprintResponsible(image) {
                this.EanFingerPrintResponsibleAssign = image;
            },
            validatedItems(item) {
                if(!item.EayBrand && !item.EayModel && !item.EaySerie){
                    return false;
                }else{
                    return true;
                }
            },
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				//console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				//console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				//console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
                //console.log(`On Before PDF Generation`)
                    html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                        // const totalPages = pdf.internal.getNumberOfPages()
                        // for (let i = 1; i <= totalPages; i++) {
                        // 	pdf.setPage(i)
                        // 	pdf.setFontSize(10)
                        // 	pdf.setTextColor(150)
                        // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        // } 
                    }).save()
            },
            saveSignaturesAndFingerPrints(){
                this.item.UsrCreateID = this.$fun.getUserID();
				this.$fun.alert("¿Seguro de guardar?", "question")
					.then(async r => {
						if(r.value){

							if (this.EanSignatureUserAssign) {
								try {
									const respImg = await GenUploadFiles.upload(this.EanSignatureUserAssign, `EanSignatureUserAssign_${this.item.EanID}`, 'routePrueba', true);
									this.item.EanSignatureUserAssign = respImg.data.fileName;
								} catch (error) {
									this.$fun.alert("Error al subir la foto", "warning");
                                    console.error("Error al subir la foto", error);
								}
							}

                            if (this.EanSignatureResponsibleAssign) {
								try {
									const respImg = await GenUploadFiles.upload(this.EanSignatureResponsibleAssign, `EanSignatureResponsibleAssign_${this.item.EanID}`, 'routePrueba', true);
									this.item.EanSignatureResponsibleAssign = respImg.data.fileName;
								} catch (error) {
                                    this.$fun.alert("Error al subir la foto", "warning");
                                    console.error("Error al subir la foto", error);
								}
							}

                            if (this.EanFingerPrintUserAssign) {
								try {
									const respImg = await GenUploadFiles.upload(this.EanFingerPrintUserAssign, `EanFingerPrintUserAssign_${this.item.EanID}`, 'routePrueba', true);
									this.item.EanFingerPrintUserAssign = respImg.data.fileName;
								} catch (error) {
                                    this.$fun.alert("Error al subir la foto", "warning");
                                    console.error("Error al subir la foto", error);
								}
							}

                            if (this.EanFingerPrintResponsibleAssign) {
								try {
									const respImg = await GenUploadFiles.upload(this.EanFingerPrintResponsibleAssign, `EanFingerPrintResponsibleAssign_${this.item.EanID}`, 'routePrueba', true);
									this.item.EanFingerPrintResponsibleAssign = respImg.data.fileName;
								} catch (error) {
                                    this.$fun.alert("Error al subir la foto", "warning");
                                    console.error("Error al subir la foto", error);
								}
							}

							await EquimentAssignamentNew.save(this.item, this.$fun.getUserID());
							this.$fun.alert("Se ha guardado correctamente", "success");
						}
					});
            }
        },
        watch: {
            dialogSignatureUser(newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        this.$refs.signaturePadUser?.resizeCanvas();
                    });
                }
            },
            dialogSignatureResponsible(newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        this.$refs.signaturePadResponsible?.resizeCanvas();
                    });
                }
            }
        },
        mounted() {
            GenUploadFiles.getFile("routePrueba", this.item.EanSignatureUserAssign, true)
            .then(base64 => {
                this.EanSignatureUserAssignDB = base64;
            })
            .catch(error => console.error("Error al obtener imagen:", error));

            GenUploadFiles.getFile("routePrueba", this.item.EanSignatureResponsibleAssign, true)
            .then(base64 => {
                this.EanSignatureResponsibleAssignDB = base64;
            })
            .catch(error => console.error("Error al obtener imagen:", error));

            GenUploadFiles.getFile("routePrueba", this.item.EanFingerPrintUserAssign, true)
            .then(base64 => {
                this.EanFingerPrintUserAssignDB = base64;
            })
            .catch(error => console.error("Error al obtener imagen:", error));

            GenUploadFiles.getFile("routePrueba", this.item.EanFingerPrintResponsibleAssign, true)
            .then(base64 => {
                this.EanFingerPrintResponsibleAssignDB = base64;
            })
            .catch(error => console.error("Error al obtener imagen:", error));
        }
    }
</script>